/* eslint-disable prettier/prettier */
import React from "react";
import { Fade } from "react-reveal";
import Posts from "../../Sections/FavPosts"

const Blog = () => {
  return (
    <section className="blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 m-auto">
            <div className="sec-heading">
              <div className="sec-title">
                <h3>Nouveautés du Blog</h3>
                <p>
                  Nos derniers articles
                </p>
              </div>
            </div>
          </div>
          <Fade>
            <Posts />
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Blog;
