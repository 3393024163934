import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

const Service = () => {
	const [responsive, setReponsive] = useState({
		0: {
			items: 2
		},
		576: {
			items: 3
		},
		768: {
			items: 4
		}
	});

	const [services, setServices] = useState({
		service: [
			{
				id: 0,
				img: 'service/1.jpg',
				alt: 'Programme NEWSTART, pics by Bradden Collum and Austris Augusts on Unsplash',
				title: 'NEWSTART'
			},
			{
				id: 1,
				img: 'service/2.jpg',
				alt: 'Programme DETOX, pic by Mariah Hewines on Unsplash',
				title: 'DETOX'
			},
			{
				id: 2,
				img: 'service/3.jpg',
				alt: 'Programme VENTRE PLAT, pic by Bill Oxford on Unsplash',
				title: 'VENTRE PLAT'
			},
			{
				id: 3,
				img: 'service/4.jpg',
				alt: 'Programme Fitness et nutrition, pics by Samuel Girven and Anna Pelzer on Unsplash',
				title: 'FITNESS & NUTRITION (à venir)'
			}
		]
	});

	const displayServices = services.service.map((serv, id) => {
		return (
			<div className="single-service" key={id}>
				<img src={`${window.location.origin}/assets/images/${serv.img}`} alt={serv.alt} />
				<div className="service-hover">
					<img src={`${window.location.origin}/assets/images/icons/1.png`} alt="Icon program" />
					<span>{serv.title}</span>*
				</div>
			</div>
		);
	});

	return (
		<section className="service">
			<OwlCarousel
				autoplay
				className="service-caro"
				dots={false}
				items={4}
				loop
				margin={5}
				nav={false}
				responsive={responsive}
			>
				{displayServices}
			</OwlCarousel>
		</section>
	);
};

export default Service;
