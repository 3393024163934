import React from 'react';
import { Link } from 'react-router-dom';

const footer = props => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<Link to="/" className="logo foo-logo">
							<img src={`${window.location.origin}/assets/images/logo.png`} alt="logo_footer" />
						</Link>
						<nav className="foo-nav">
							<ul>
								<li>
									<Link to="/">Accueil</Link>
								</li>
								<li>
									<Link to="/about">À Propos</Link>
								</li>
								<li>
									<Link to="/services">Services</Link>
								</li>
								<li>
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</nav>
						<div className="foo-social">
							<Link to="/facebook">
								<i className="ti-facebook" />
							</Link>
							<Link to="/twitter">
								<i className="ti-twitter-alt" />
							</Link>
							<Link to="/linkedin">
								<i className="ti-linkedin" />
							</Link>
							<Link to="/instagram">
								<i className="ti-instagram" />
							</Link>
						</div>
						<p className="copyright">
							&copy; COPYRIGHT 2021 <a href="http://shadify-in.com">Mickaël Sorhaindo</a> ALL RIGHTS RESERVED.
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default footer;
