import React from 'react';
import SinglePrice from '../Components/SinglePrice';

const catalog = [
	{
		name: 'Bilan diététique',
		check: false,
		shopping: false,
		duration: { max: true, time: 90 },
		price: { standard: 65 },
		infos: ['Gestion du poids', 'Rééquilibrage alimentaire'],
		includes: ['Vos habitudes alimentaires', 'Votre mode de vie', 'Compte rendu sous 72h', 'Conseils ciblés'],
		bonus: ['Carnet alimentaire', 'Livret de recette'],
		options: [],
		notIncludes: [],
		noOptions: []
	},
	{
		name: 'Consultation',
		check: false,
		shopping: false,
		duration: { max: true, time: 60 },
		price: { standard: 50 },
		includes: [
			'Mise en place des habitudes',
			'Point sur le programme',
			'Analyse de vos difficultés',
			'Adapter le programme',
			'Conseils ciblés'
		],
		bonus: [],
		options: [],
		notIncludes: [],
		noOptions: []
	},
	{
		name: 'N.E.W.S.T.A.R.T',
		check: false,
		shopping: false,
		duration: { max: false, time: 21 },
		price: { standard: 250, check: 265, shopping: 40 },
		includes: [
			'Bilan diététique',
			'Programme personnalisé',
			'Planning nutritionnel',
			'1 semaine détox',
			'1 semaine de revitalisation',
			'1 semaine de stabilisation',
			'Adapter le programme',
			'Conseils ciblés',
			'Consultation de fin de programme'
		],
		checkValue: ['1 consultation/ semaine', 'Accompagnement quotidien 5/7j'],
		bonus: ['Carnet alimentaire', 'Livret de recette', 'Consultation post-programme (offert)'],
		options: ['liste des courses'],
		notIncludes: ['1 consultation/ semaine', 'Accompagnement quotidien 5/7j'],
		noOptions: ['liste des courses']
	},
	{
		name: 'Détox',
		check: true,
		shopping: false,
		duration: { max: false, time: 10 },
		price: { standard: 110, check: 50, shopping: null },
		includes: ['Bilan diététique', 'programme détox'],
		checkValue: ['Consultation de fin de programme', 'planning nutritionnel post-programme'],
		bonus: ['Livret de recette détox'],
		options: [],
		notIncludes: ['Consultation de fin de programme', 'planning nutritionnel post-programme'],
		noOptions: []
	}
];

const prices = catalog.map(price => <SinglePrice key={price.name} price={price} />);

const PricingTable = () => {
	return (
		<div className="h-full bg-gradient-to-tl from-green-400 to-blue-600">
			<div className="flex justify-around w-full py-16">
				<div className="flex flex-wrap justify-center">{prices}</div>
			</div>
		</div>
	);
};

export default PricingTable;
