/* eslint-disable prettier/prettier */
import React from 'react';

const Appointment = props => {
	return (
		<section className="appointment">
			<div className="appointment-wrap">
				<figure>
					<img src={`${window.location.origin}/assets/images/appointment-img.jpg`} alt="Appointment Pic" />
				</figure>
        <div className="appointment-form">
          <form action="#">
            <div className="form-field half-width">
              <input type="text" placeholder="Nom" required />
              <input type="email" placeholder="Adresse Email" required />
            </div>
            <div className="form-field half-width">
              <div className="select-field">
                <select name="services" id="services">
                  <option value="none">---- Select a program ----</option>
                  <option value="none">Newstart</option>
                  <option value="none">Detox</option>
                  <option value="none">minceur</option>
                  <option value="none">fitness & nutrition</option>
                </select>
              </div>
              <input type="tel" placeholder="Numéro de téléphone"/>
            </div>
            <div className="form-field half-width">
              <input type="date" placeholder="Date"/>
              <input type="time" placeholder="Heure"/>
            </div>
            <div className="form-field">
              <textarea
                name="message"
                id="message"
                placeholder="Votre message"
                cols="30"
                rows="10"
              />
            </div>
            <div className="btn btn-round">Prendre R.D.V</div>
          </form>
        </div>
			</div>
		</section>
	);
};

export default Appointment;
