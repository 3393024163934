import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Layouts/Pages/Home';
import Blog from './Layouts/Pages/Blog';
import About from './Layouts/Pages/About';
import Services from './Layouts/Pages/Services';
import Contact from './Layouts/Pages/Contact';

const Routes = () => {
	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/blog" component={Blog} />
			<Route exact path="/about" component={About} />
			<Route exact path="/services" component={Services} />
			<Route exact path="/contact" component={Contact} />
		</Switch>
	);
};

export default Routes;
