import React from 'react';
import { Fade, Rotate } from 'react-reveal';
import { Link } from 'react-router-dom';

const aboutSec = props => {
	return (
		<section className="about">
			<div className="container">
				<div className="row">
					<div className="col-lg-10 col-md-12 m-auto">
						<div className="sec-heading">
							<Rotate duration={2000}>
								<img src={`${window.location.origin}/assets/images/medo_icon.png`} alt="Icone pour l'entreprise Medo" />
							</Rotate>
							<span className="tagline">Mieux me connaître</span>
							<h3 className="sec-title">Qui suis-je?</h3>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic quisquam adipisci libero delectus atque
								veniam pariatur excepturi perspiciatis similique! In inventore, voluptates sint tenetur error maxime
								quis cupiditate natus repudiandae?
							</p>
						</div>
						<Fade bottom>
							<Link to="about" className="btn btn-round">
								En savoir plus
							</Link>
						</Fade>
					</div>
				</div>
			</div>
		</section>
	);
};

export default aboutSec;
