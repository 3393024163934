import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

const Banner = props => {
	return (
		<section className="banner">
			<div className="medo-img">
				<Fade>
					<img src={`${window.location.origin}/assets/images/medo.png`} alt="background Medo banner" />
				</Fade>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<Fade>
							<div className="banner-content">
								<span>Détox & Rééquilibrage alimentaire</span>
								<h2>Prenez soin de vous.</h2>
								<p>
									Au travers de nos différents programmes, retrouvez une bonne hygiène alimentaire. Apprenez à écouter
									votre corps, comprendre ses besoins et y répondre.
								</p>
								<Fade bottom>
									<Link to="/services" className="btn">
										En savoir plus
									</Link>
								</Fade>
							</div>
						</Fade>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
