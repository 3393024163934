import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

function Navbar(props) {
	const [menuOpen, setMenuOpen] = useState(false);
	const [scrolled, setScrolled] = useState(false);

	const headerClasses = [];
	if (scrolled) {
		headerClasses.push('scrolled');
	}

	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 60) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	});

	const menuToggleHandler = () => {
		setMenuOpen(!menuOpen);
	};

	const clearStateHandler = () => {
		setMenuOpen(!menuOpen);
	};

	const absHeader = props.location.pathname === '/' ? `abs-header ${headerClasses}` : '';
	const right = props.location.pathname === '/' ? '' : 'text-right';

	return (
		<header className={`header ${absHeader}`}>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-3">
						<NavLink to="/" className="logo">
							<img src={`${window.location.origin}/assets/images/logo.png`} alt="logo" />
						</NavLink>
					</div>
					<div className={`col-md-9 ${right}`}>
						<nav className="primary-menu">
							<button type="button" className="mobile-menu" onClick={menuToggleHandler}>
								<i className="ti-menu" />
							</button>
							<ul className={menuOpen ? 'active' : ''}>
								<li>
									<NavLink to="/" onClick={clearStateHandler}>
										Accueil
									</NavLink>
								</li>
								<li>
									<NavLink to="/about" onClick={clearStateHandler}>
										À Propos
									</NavLink>
								</li>
								<li>
									<NavLink to="/services" onClick={clearStateHandler}>
										Services
									</NavLink>
								</li>
								<li>
									<NavLink to="/blog" onClick={clearStateHandler}>
										Blog
									</NavLink>
								</li>
								<li>
									<NavLink to="/contact" onClick={clearStateHandler}>
										Contact
									</NavLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</header>
	);
}

export default withRouter(Navbar);
