/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import Post from "../Components/Post";

const post1 = "0.jpg"
const post2 = "1.jpg"
const post3 = "2.jpg"

const FavPosts = () => {
  const [posts, setPosts] = useState({
    data: [
      {
        id:0,
        alt: 'blog article',
        title: 'Mon premier article',
        excerpt: 'Quia et suscipit suscipit recusandae consequuntur expedita et cum reprehenderit molestiae ut ut quas totam nostrum rerum est autem sunt rem eveniet architecto',
        body:'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
        url: post1
      },
      {
        id:1,
        alt: 'blog article',
        title: 'deuxième article',
        excerpt: 'Est rerum tempore vitae sequi sint nihil reprehenderit dolor beatae ea dolores neque fugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis qui aperiam non debitis possimus qui neque nisi nulla',
        body:'Est rerum tempore vitae sequi sint nihil reprehenderit dolor beatae ea dolores neque fugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis qui aperiam non debitis possimus qui neque nisi nulla.Est rerum tempore vitae sequi sint nihil reprehenderit dolor beatae ea dolores neque fugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis qui aperiam non debitis possimus qui neque nisi nulla.',
        url: post2
      },
      {
        id:2,
        alt: 'blog article',
        title: 'troisième article',
        excerpt: 'Et iusto sed quo iure voluptatem occaecati omnis eligendi aut ad voluptatem doloribus vel accusantium quis pariatur molestiae porro eius odio et labore et velit aut',
        body:'Et iusto sed quo iure voluptatem occaecati omnis eligendi aut ad voluptatem doloribus vel accusantium quis pariatur molestiae porro eius odio et labore et velit aut.Et iusto sed quo iure voluptatem occaecati omnis eligendi aut ad voluptatem doloribus vel accusantium quis pariatur molestiae porro eius odio et labore et velit aut.',
        url: post3
      }
    ]
});
  const displayPosts = posts.data.map(post => {
    return (
      <Post
        key={post.id}
        id={post.id}
        title={post.title}
        excerpt={post.excerpt}
        body={post.body}
        url={`${window.location.origin}/assets/images/post/${post.url}`}
      />
    )
  })
  return (
    <div className="row">
      { displayPosts }
    </div>
  )
};

export default FavPosts;
