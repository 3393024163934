/* eslint-disable prettier/prettier */
import React from 'react';
import ContactSec from '../../Sections/Contact';

const Contact = props => {
	return (
		<>
			<ContactSec {...props} />
		</>
	);
};

export default Contact;
