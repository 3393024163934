import React from 'react';
import MemberSocial from './MemberSocial';

const SingleMember = props => {
	return (
		<div className="single-memb">
			<img src={props.profile} alt="profile" />
			<div className="memb-details">
				<h6>{props.name}</h6>
				<span>{props.speciality}</span>
				<MemberSocial socialLinks={props.social} />
			</div>
		</div>
	);
};

export default SingleMember;
