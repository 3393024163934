import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './Layouts/Layout';
import Routes from './Routes';

import './css/themify-icons.css';
import './css/bootstrap.min.css';
import './css/style.css';

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Layout>
					<Routes />
				</Layout>
			</div>
		</BrowserRouter>
	);
}

export default App;
