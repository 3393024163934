/* eslint-disable prettier/prettier */
import React from 'react';

const SingleTestimonial = props => {
	return (
		<div className="single-tst">
			<img src={`${window.location.origin}/assets/images/quote.png`} alt="" />
			<p>{props.body}</p>
			<div className="client-info">
				<img src={`${window.location.origin}/assets/images/client-1.png`} alt="" className="thumb" />
				<p>
					{props.name}, <span>{props.designation}</span>
				</p>
			</div>
		</div>
	);
};

export default SingleTestimonial;
