/* eslint-disable prettier/prettier */
import React from "react";
import { Link } from "react-router-dom";

const post = (props) => {
  return (
    <div className="col-md-4">
      <div className="post">
        <img src={props.url} alt={props.alt} />
        <h4>
          <Link to={`/blog/${props.id}`}>{props.title}</Link>
        </h4>
        <p>{props.excerpt.substring(0, 157)} ...</p>
        <Link to={props.url} className="btn btn-round">En savoir plus</Link>
      </div>
    </div>
  );
};

export default post
