import React from 'react';
import { Fade } from 'react-reveal';
import Banner from '../../Sections/Banner';
import AboutSec from '../../Sections/About';
import Service from '../../Sections/Service';
import Testimonial from '../../Sections/Testimonial';
// import Blog from './Blog';

const home = () => {
	return (
		<>
			<Banner />
			<Fade bottom>
				<AboutSec />
			</Fade>
			<Fade left>
				<Service />
			</Fade>
			{/* <Fade bottom> */}
			{/*	<Blog /> */}
			{/* </Fade> */}
			<Fade right>
				<Testimonial />
			</Fade>
		</>
	);
};

export default home;
