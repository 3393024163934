/* eslint-disable prettier/prettier */
import React from 'react';
import { Fade } from 'react-reveal';
import AboutSec from '../../Sections/About';
import Team from '../../Sections/Team';
import Testimonial from '../../Sections/Testimonial';

const About = () => {
	return (
		<>
			<AboutSec />
			<Fade bottom>
				<Team />
			</Fade>
			<Fade left>
				<Testimonial />
			</Fade>
		</>
	);
};

export default About