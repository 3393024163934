/* eslint-disable prettier/prettier */
import React from 'react';

const memberSocial = props => {
	// console.log(props.socialLinks)

	const links = Object.keys(props.socialLinks).map(link => {
		// console.log( link )
		switch (link) {
			case 'facebook':
				return (
					<a href={props.socialLinks[link]} key={link}>
						<i className="ti-facebook" />
					</a>
				);
			case 'twitter':
				return (
					<a href={props.socialLinks[link]} key={link}>
						<i className="ti-twitter-alt" />
					</a>
				);
			case 'googlePlus':
				return (
					<a href={props.socialLinks[link]} key={link}>
						<i className="ti-google" />
					</a>
				);
			case 'linkedin':
				return (
					<a href={props.socialLinks[link]} key={link}>
						<i className="ti-linkedin" />
					</a>
				);
			case 'instagram':
				return (
					<a href={props.socialLinks[link]} key={link}>
						<i className="ti-instagram" />
					</a>
				);
			default:
				return null;
		}
	});

	return <div className="memb-social">{links}</div>;
};

export default memberSocial;
