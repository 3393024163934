/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import SingleTestimonial from '../Components/SingleTestimonial';
import 'owl.carousel/dist/assets/owl.carousel.css';

const Testimonial = () => {
	const [testimonials, setTestimonials] = useState({
		data: [
			{
				userId: 12,
				userName: 'Kr0n0s',
				designation: 'programme NEWSTART',
				body:
					"J'ai suivi le programme NEWSTART développé par Medo, dès la première semaine j'ai perdu 3kg et je n'ai pas cessé de perdre jusqu'à une stabilisation totale."
			},
			{
				userId: 13,
				userName: 'Myke',
				designation: 'programme Ventre plat',
				body:
					"Suite à une mauvaise alimentation et un manque de sport, je me suis vu prendre plusieurs kilos en quelques années. Grâce à ce programme, jumelé avec un programme detox, j'ai pu perdre plusieurs kilos mais plus encore, j'ai appris quels étaient les besoins de mon corps. Depuis j'ai trouver un équilibre alimentaire. Merci Medo!"
			}
		]
	});

	let testimonialsLoading = 'Loading...';

	if (testimonials.data.length) {
		testimonialsLoading = (
			<OwlCarousel autoplay className="test-caro" dots items={1} loop margin={0} nav={false}>
				{testimonials.data.map(testimonial => {
					return (
						<SingleTestimonial
							key={testimonial.userId}
							id={testimonial.userId}
							name={testimonial.userName}
							designation={testimonial.designation}
							body={testimonial.body}
						/>
					);
				})}
			</OwlCarousel>
		);
	}
	return (
		<section className="testimonial bg-lightred">
			<div className="container">
				<div className="row">
					<div className="col-lg-10 col-md-12 m-auto">{testimonialsLoading}</div>
        </div>
			</div>
		</section>
	);
};

export default Testimonial;
