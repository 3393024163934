/* eslint-disable prettier/prettier */
import React from 'react';
import { Fade } from 'react-reveal';
// import Pricing from '../../Sections/Pricing';
import Appointment from '../../Sections/Appointment';
import PricingTable from '../../Sections/PricingTable';

const Services = props => {
	return (
		<>
    <Fade bottom>
      {/* <Pricing /> */}
      <PricingTable />
    </Fade>
    <Fade bottom>
			<Appointment />
    </Fade>
		</>
	);
};

export default Services;
