/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

const Contact = () => {
	const [contactInfo, setContactInfo] = useState({
		contact: {
			name: '',
			email: '',
			message: ''
		},
		messagePosted: false
  });
  
  const inputChangeHandler = e => {
    const updatedContactInfo = { ...contactInfo.contact }
    updatedContactInfo[e.target.name] = e.target.value

    setContactInfo({...contactInfo, contact: updatedContactInfo})
  }

  const onSubmitHandler = e => {
    e.preventDefault()
  }
	return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 m-auto">
            <div className="sec-heading">
              <h3 className="sec-title">Contactez nous</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <address>
              <span className="ti-email" />
              <a href="mailto:contact@medo.fr">contact@medo.fr</a>
              <a href="mailto:coaching@medo.fr">coaching@medo.fr</a>
            </address>
            <address className="column align-items-center">
              <span className="ti-mobile" />
              <a href="tel:+33612345678">+33612345678</a>
              {/* <a href="tel:+33712345678">+33712345678</a> */}
            </address>
          </div>
          <div className="col-md-7 offset-md-1">
            <form className="contact-form" onSubmit={onSubmitHandler}>
              <input 
                type="text" 
                name="name" 
                placeholder="Name" 
                onChange={inputChangeHandler} 
                value={contactInfo.contact.name}
                required
              />
              <input 
                type="email" 
                name="email" 
                placeholder="Adresse Email" 
                onChange={inputChangeHandler} 
                value={contactInfo.contact.email}
                required
              />
              <textarea 
                name="message" 
                placeholder="Ecrivez votre message" 
                onChange={inputChangeHandler} 
                value={contactInfo.contact.message}
                required
               />
               <button type="submit" className="btn btn-round">Envoyer</button>
            </form>
          </div>
        </div>
      </div>
    </section>);
};

export default Contact;
