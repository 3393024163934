/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import SingleMember from '../Components/SingleMember';

const profile1 = '1.jpg';
const profile2 = '2.jpg';
const profile3 = '3.jpg';

const Team = () => {
	const [myTeam, setMyTeam] = useState({
		members: [
			{
				id: 1,
				name: 'Myke Kr0n0s',
				specialist: 'Développement Web & Informatique',
				social: {
					facebook: 'https://facebook.com',
					linkedin: 'https://linkedin.com',
					instagram: 'https://instagram.com'
				},
				profile: profile2
			},
			{
				id: 0,
				name: 'Natur O\'Kat',
				specialist: 'Coaching nutritionnel & Rééquilibrage Alimentaire',
				social: {
					facebook: 'https://facebook.com',
					linkedin: 'https://linkedin.com',
					instagram: 'https://instagram.com'
				},
				profile: profile1
			},
			{
				id: 2,
				name: 'Jane Doe',
				specialist: 'Musicothérapie & Sciences de la ',
				social: {
					facebook: 'https://facebook.com',
					linkedin: 'https://linkedin.com',
					instagram: 'https://instagram.com'
				},
				profile: profile3
			}
		]
	});

	const members = myTeam.members.map(member => {
		return (
			<div className='col-md-4' key={member.id}>
				<SingleMember
					id={member.id}
					name={member.name}
					speciality={member.specialist}
					social={member.social}
					profile={`${window.location.origin}/assets/images/team/${member.profile}`}
				/>
			</div>
		);
	});
	return (
		<section className='team'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 col-md-9 m-auto'>
						<div className='sec-heading'>
							<h3 className='sec-title'>L'équipe</h3>
						</div>
					</div>
				</div>
				<div className='row'>
					{members}
				</div>
			</div>
		</section>
	);
};

export default Team;
