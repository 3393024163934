import React, { useState } from 'react';
import { Switch } from '@material-ui/core';

const SinglePrice = props => {
	const { price } = props;
	const [inc, setInc] = useState(false);
	const [shopping, setShopping] = useState(false);
	const totalPrice = () => {
		let result = price.price.standard;
		if (inc && !shopping) {
			result = price.price.standard + price.price.check;
		}
		if (shopping && !inc) {
			result = price.price.shopping + price.price.standard;
		}
		if (inc && shopping) {
			result = price.price.standard + price.price.check + price.price.shopping;
		}
		return result;
	};

	const includes = () => {
		return price.includes.map(include => {
			return (
				<li key={include} className="mb-3">
					{include}
				</li>
			);
		});
	};

	const notIncludes = () => {
		return (
			!inc &&
			price.notIncludes.map(notInc => (
				<li key={notInc} className="mb-3 text-red-800">
					{notInc}
				</li>
			))
		);
	};

	const checkValues = () => {
		return (
			inc &&
			price.checkValue.map(value => {
				return (
					<li key={value} className="mb-3 text-green-800">
						{value}
					</li>
				);
			})
		);
	};

	const notOptions = () => {
		return (
			!shopping &&
			price.noOptions &&
			price.noOptions.map(notInc => (
				<li key={notInc} className="mb-3 text-red-800">
					{notInc}
				</li>
			))
		);
	};

	const options = () => {
		return (
			shopping &&
			price.options &&
			price.options.map(value => {
				return (
					<li key={value} className="mb-3 text-green-800">
						{value}
					</li>
				);
			})
		);
	};

	return (
		<div className="my-3 mx-2 sm:w-auto w-full flex justify-center">
			<div className="sm:w-80 w-4/5 p-8 bg-white text-center rounded-3xl flex-col shadow-xl">
				<h4 className="text-black font-semibold text-2xl">
					{price.name}
					<span className="text-sm">
						{' '}
						- {price.duration.time}
						{price.duration.max ? 'min' : 'j'}
					</span>
				</h4>
				<p className="pt-2 tracking-wide">
					<span className="text-3xl font-semibold">{totalPrice()}</span>
					<span className="text-gray-400 align-top">€</span>
					<span className="text-gray-400 font-medium">{null}</span>
				</p>
				<hr className="border-4 rounded-xl bg-gradient-to-r from-blue-400 to-blue-500" />
				<div className="pt-8 h-full">
					<ul className="my-2 text-sm text-left font-normal">
						{includes()}
						{<Switch checked={inc} onChange={() => setInc(!inc)} name="Includes" color="primary" />}
						{<Switch checked={shopping} onChange={() => setShopping(!shopping)} name="Options" color="primary" />}
						{checkValues()}
						{options()}
						{notIncludes()}
						{notOptions()}
					</ul>
					<a
						href="/services"
						type="button"
						className="w-full p-4 bg-gradient-to-tl from-blue-500 to-blue-600 mt-8 rounded-xl text-white"
					>
						Choose Plan
					</a>
				</div>
			</div>
		</div>
	);
};

export default SinglePrice;
